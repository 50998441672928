import React, {
  memo, useCallback, useEffect, useMemo, useState
} from 'react';
import Bar from '@common/components/bar';
import Container from '@common/components/container';
import Card from '@common/components/card';
import Table from '@common/components/table';
import type { ExtendedUser, LoggedUser } from '@common/types/objects';
import { Trans, useTranslation } from 'react-i18next';
import { Api } from '@common/services/api';
import { selected } from '@modules/organisation/selectors/organisation';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Button } from '@common/components/button';
import Placeholder from '@common/components/placeholder/placeholder';
import ModalWindow from '@common/components/modal/modal-window';
import ModalContent from '@common/components/modal/modal-content';
import { Alert } from '@common/components/alert';
import Spinner from '@common/components/spinner';
import { FormAnswer } from '@modules/forms/components/answer';
import { PureList } from '@common/components/list';
import { Overview } from '@common/components/overview';

const surveyResponsesTableRow = ({ item }: any) => {
  // console.log("debug item", item);
  return [
    item?.survey?.title,
    item?.assigned_at ? moment(item.assigned_at).format('MMM D, YYYY HH:mm') : '-',
    item?.created_at ? moment(item.created_at).format('MMM D, YYYY HH:mm') : '-',
  ];
};

export const ResponsesModalContent = memo(({
  title, onHide, submission
}: any) => {
  const { t } = useTranslation();
  return (
    <ModalContent
      title={title}
      hideFooter
      onHide={onHide}
    >
      {
        submission ?
          (
            <Overview.Content className="Survey__Response">
              <PureList
                containerClassName="Response__Answers"
                items={submission.answers}
                header={(
                  <div className="Response__Header">
                    <h1 className="fs-exclude">
                      {
                        t('forms:form_submission_title', {
                          name: submission.user?.full_name
                        })
                      }
                    </h1>

                  </div>
                )}
                // @ts-expect-error
                renderRow={FormAnswer}
                rowProps={{
                  timezone: submission.timezone,
                }}
                placeholder={t('common:select_no_results')}
              />
            </Overview.Content>
          ) : (
            <Spinner size="large" centered />
          )
      }
    </ModalContent>
  );
});

const SurveyResponses = memo(({ item, onHide }: any) => {
  const [responses, setResponses] = useState(null);
  // console.log("debug item", item);
  const responseId = item?.id;
  const surveyId = item?.survey?.id;
  const orgId = item?.organisation_id;
  useEffect(() => {
    const surveyUrl = `/v1/organisations/${orgId}/surveys/${surveyId}`;
    const responseUrl = `${surveyUrl}/responses/${responseId}`;
    Api.get(responseUrl).then((result: any) => {
      setResponses(result);
    });
  }, [orgId, responseId, surveyId, setResponses]);

  // @ts-expect-error
  const submission = responses?.data;

  return (
    <ResponsesModalContent
      title={item?.survey?.title}
      onHide={onHide}
      submission={submission}
    />
  );
});

const ViewSurveyResponsesAction = memo(({ item }: any) => {
  // console.log("debug ViewSurveyResponsesAction props", props);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const onHide = useCallback(() => setShow(false), [setShow]);
  const onShow = useCallback(() => setShow(true), [setShow]);
  return (
    <ModalWindow
      trigger={<Button type="soft">{t('forms:view_response')}</Button>}
      show={show}
      onHide={onHide}
      onShow={onShow}
    >
      <SurveyResponses onHide={onHide} item={item} />
    </ModalWindow>
  );
});

type FormsTableProps = {
  organisationId: string;
  userId: string;
};

const ResponsesTable = memo(({ organisationId, userId }: FormsTableProps) => {
  const { t } = useTranslation();

  const onFetch = useCallback(async (nextCursor: string | null) => {
    let url = `/v1/organisations/${organisationId}/users/${userId}`;
    url += `/surveys/responses?limit=20&cursor=${nextCursor || 'true'}`;
    return Api.get(url);
  }, [organisationId, userId]);

  const columns = useMemo(() => {
    return [{
      label: t('survey:responses_header_name')
    }, {
      size: 180,
      label: t('survey:surveys_column_assigned_at')
    }, {
      size: 180,
      label: t('survey:responses_header_date_responded')
    }];
  }, [t]);

  return (
    // @ts-expect-error
    <Table
      columns={columns}
      data={{
        useCursor: true,
        onFetch
      }}
      renderRow={surveyResponsesTableRow}
      ActionComponent={ViewSurveyResponsesAction}
      placeholder={<Placeholder title={t('survey:responses_placeholder_title')} />}
    />
  );
});

type UserProfileSurveysProps = {
  user: ExtendedUser | LoggedUser;
};

const UserProfileSurveys = memo(({ user }: UserProfileSurveysProps) => {
  const { t } = useTranslation();

  const org = useSelector(selected);

  return (
    <Container.Content>
      <Bar>
        <h2>{ t('core:surveys_overview') }</h2>
      </Bar>
      <Container.Column>
        <Alert
          type="warning"
          title={t('survey:non-anonymous_surveys')}
        >
          <Trans
            i18nKey="survey:non-anonymous_surveys_description"
            components={
              [
                <a
                  href="https://help.oneteam.io/en/articles/5373831-create-send-and-export-surveys"
                  target="_blank"
                  rel="noreferrer"
                />
              ]
            }
          />
        </Alert>
        <Card>
          <ResponsesTable userId={user.id} organisationId={org.id} />
        </Card>
      </Container.Column>
    </Container.Content>
  );
});

export default UserProfileSurveys;
