import React, {
 memo, useCallback, useMemo, useState, useEffect
} from 'react';
import Container from '@common/components/container';
import Card from '@common/components/card';
import type { ExtendedUser, LoggedUser } from '@common/types/objects';
import { useTranslation } from 'react-i18next';
import { Api } from '@common/services/api';
import { selected } from '@modules/organisation/selectors/organisation';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Placeholder from '@common/components/placeholder/placeholder';
import { AsyncList } from '@common/components/list';
import Spinner from '@common/components/spinner';
import ModuleIcon from '@modules/learning/components/module-icon';
import { CourseCell, Row } from './user-profile-onboarding';

const tableRow = ({ item, userId }: any) => {
  const startedAt = item?.progress?.started_at;
  const assignedAt = item?.assigned_at;
  const finishedAt = item.progress?.finished_at;
  const deadline = item?.progress?.deadline;
  const currentPoints = item?.progress?.current_points;
  const maxPoints = item?.progress?.max_points;

  const [open, setOpen] = useState(false);

  const [courseInfo, setCourseInfo] = useState<any>(null);

  const organisationId = item?.organisation_id;
  const courseId = item?.id;
  useEffect(() => {
    if (open && !courseInfo) {
      // GET {{base_url}}/v2/organisations/180/users/4/courses/{courseId}
      const base = `/v2/organisations/${organisationId}`;
      const url = `${base}/users/${userId}/courses/${courseId}?type=academy`;
      Api.get(url).then((result: any) => {
        // console.log("debug result", result);
        setCourseInfo(result);
      });
    }
  }, [open, courseInfo, organisationId, userId, courseId, setCourseInfo]);

  const modules = useMemo(() => {
    return courseInfo?.data?.sections?.map((section: any) => {
      return section?.modules;
    }).flat();
  }, [courseInfo]);

  // console.log("debug modules", modules);

  return (
    <>
      <Row className={item.deleted_at ? 'deleted' : ''}>
        <CourseCell setOpen={setOpen} open={open} item={item} />
        <div className="Table__Cell">
          { assignedAt ? moment(assignedAt).format('D MMM YYYY') : '-' }
        </div>
        <div className="Table__Cell">
          { startedAt ? moment(startedAt).format('D MMM YYYY') : '-' }
        </div>
        <div className="Table__Cell">
          { finishedAt ? moment(finishedAt).format('D MMM YYYY') : '-' }
        </div>
        <div className="Table__Cell">
          { deadline ? moment(deadline).format('D MMM YYYY') : '-' }
        </div>
        <div className="Table__Cell">
          { currentPoints + '/' + maxPoints }
        </div>
      </Row>
      {
        open ?
          (
            modules ?
              modules.map((module: any) => {
                const progress = module?.progress;
                const start = progress?.started_at;
                const finished = progress?.finished_at;
                return (
                  <Row key={module.id} className="moduleRow">
                    <div className="Table__Cell courseCell">
                      <ModuleIcon module={module} />
                      <span>{ module?.name }</span>
                    </div>
                    <div className="Table__Cell" />
                    <div className="Table__Cell">
                      { start ? moment(start).format('D MMM YYYY') : '-' }
                    </div>
                    <div className="Table__Cell">
                      { finished ? moment(finished).format('D MMM YYYY') : '-' }
                    </div>
                    <div className="Table__Cell" />
                    <div className="Table__Cell">
                      {/* eslint-disable-next-line no-unsafe-optional-chaining */}
                      { progress?.current_points + '/' + progress?.max_points }
                    </div>
                  </Row>
                );
              }) :
              <Spinner centered />
          ) :
          null
      }
    </>
  );
};

type ELearningTableProps = {
  organisationId: string;
  userId: string;
};

const ELearningTable = memo(({
  organisationId,
  userId
}: ELearningTableProps) => {

  const { t } = useTranslation();

  const onFetch = useCallback(() => {
    const url = `/v2/organisations/${organisationId}/users/${userId}`;
    return Api.get(url + '/courses?type=academy');
  }, [organisationId, userId]);

  const columns = useMemo(() => {
    return [{
      label: t('core:course_header')
    }, {
      label: t('learning:onboarding_statistics_column_label_assigned')
    }, {
      label: t('core:course_header_started')
    }, {
      label: t('learning:course_statistics_column_finished')
    }, {
      label: t('learning:onboarding_statistics_column_label_deadline')
    }, {
      label: t('core:course_header_score')
    }];
  }, [t]);

  return (
    <AsyncList
      containerClassName="Table expandableRowsTable"
      header={(
        <div className="Table__Row Table__Header">
          {
            columns.map((column: any) => {
              return (
                <div className="Table__Cell" key={column.label}>
                  <h5>{ column.label }</h5>
                </div>
              );
            })
          }
        </div>
      )}
      columns={columns}
      data={{
        // @ts-expect-error
        useCursor: false,
        // @ts-expect-error
        onFetch
      }}
      renderRow={tableRow}
      rowProps={{ userId }}
      placeholder={<Placeholder title={t('common:select_no_results')} />}
    />
  );
});

type UserProfileELearningProps = {
  user: ExtendedUser | LoggedUser;
};

const UserProfileELearning = memo(({ user }: UserProfileELearningProps) => {
  const org = useSelector(selected);

  return (
    <Container.Content>
      <Container.Column>
        <Card>
          <ELearningTable userId={user.id} organisationId={org.id} />
        </Card>
      </Container.Column>
    </Container.Content>
  );
});

export default UserProfileELearning;
