import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useAppSelector, useThunkDispatch } from '@common/hooks/redux';
import { Select } from '@common/components/form/inputs/select';
import { Button } from '@common/components/button';
import { AlertService } from '@services/alert';
import * as organisationSelectors from '../../../organisation/selectors/organisation';
import { addUserToFunctionGroup } from '../../actions';

import type { ExtendedUser, OrganisationFunction } from '@common/types/objects';

type AddUserToFunctionGroupProps = {
  user: ExtendedUser;
};

export const AddUserToFunctionGroup = ({ user }: AddUserToFunctionGroupProps) => {
  const { t } = useTranslation();
  const thunkDispatch = useThunkDispatch();
  const params = useParams<{ networkId?: string, userId: string }>();
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFunction, setSelectedFunction] = useState<OrganisationFunction>();
  const functionGroups = useAppSelector(organisationSelectors.functions);

  const handleSubmit = async () => {
    if (!selectedFunction) return;

    setIsSubmitting(true);

    try {
      await thunkDispatch(addUserToFunctionGroup(user.id, selectedFunction, params.networkId));

      setIsOpen(false);
      setSelectedFunction(undefined);

      AlertService.success(t('core:add_to_function_group', { context: 'success' }));
    } catch (e: any) {
      if (e && typeof e === 'object' && 'status_code' in e) {
        AlertService.forStatus(e.status_code, {
          warning: t('core:add_to_function_group', { context: 'warning' }),
          error: t('core:add_to_function_group', { context: 'error' }),
        });
      }
    }

    setIsSubmitting(false);
  };

  if (!isOpen) {
    return (
      <span className="AddUserToFunctionGroup__ShowButton" onClick={() => setIsOpen(true)}>
        {t('core:add_to_function_group')}
      </span>
    );
  }

  return (
    <div className="AddUserToFunctionGroup">
      <Select
        // @ts-expect-error
        onChange={setSelectedFunction}
        value={selectedFunction}
        options={functionGroups.filter(({ id }) => !user.scopes.functions.some((functionGroup) => functionGroup.id === id))}
        labelKey="name"
        valueKey="id"
      />

      <div className="AddUserToFunctionGroup__ActionButtons">
        <Button onClick={() => setIsOpen(false)}>
          {t('common:confirm_button_cancel')}
        </Button>
        <Button type="primary" isLoading={isSubmitting} onClick={handleSubmit}>
          {t('core:add')}
        </Button>
      </div>
    </div>
  );
};
