import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { Field, BaseFieldProps } from 'redux-form';

import { Select } from '@common/components/form/inputs/select';
import Icon, { IconTypes } from '../../../../common/components/icon';
import { EPrivacyLevels } from '../../../../common/definitions';

type Props = {
  value: string | boolean,
  onChange: (value: string) => void,
  onlyBoolean?: boolean,
  t: (key: string, values?: Object) => string,
};

const valueComponent = ({ value: option }: { value: { icon: string, label: string } }) => (
  <div className="Select-value">
    <span className="Select-value-label">
      <Icon type={option.icon as IconTypes} />
      {option.label}
    </span>
  </div>
);

const optionComponent = ({ option, isSelected, onSelect }: {
  option: { icon: string, value: string, label: string, description: string },
  isSelected: boolean,
  onSelect: (value: string) => void,
}) => {
  const classNames = ['Select-option PrivacySelectOption'];
  if (isSelected) classNames.push('is-selected');

  return (
    <div className={classNames.join(' ')} onClick={!isSelected ? () => onSelect(option.value) : undefined}>
      {option.icon && <Icon type={option.icon as IconTypes} isFilled={isSelected} />}
      <div className="PrivacySelectOption__Text">
        <div className="PrivacySelectOption__Label">{option.label}</div>
        <small className="PrivacySelectOption__Description">{option.description}</small>
      </div>
    </div>
  );
};

const PrivacySelectComponent = ({ value, onChange, onlyBoolean, t }: Props) => {
  const options: Array<Object> = [
    {
      value: EPrivacyLevels.ORGANISATION,
      icon: 'group',
      label: t('core:privacy_option_everyone'),
      description: t('core:privacy_option_everyone_description'),
    },
    !onlyBoolean && {
      value: EPrivacyLevels.NETWORK,
      icon: 'business',
      label: t('core:privacy_option_network'),
      description: t('core:privacy_option_network_description'),
    },
    {
      value: EPrivacyLevels.NOBODY,
      icon: 'lock',
      label: t('core:privacy_option_no_one'),
      description: t('core:privacy_option_no_one_description'),
    },
  ].filter((o) => !!o);

  return (
    <Select
      className="PrivacySelect"
      clearable={false}
      value={value}
      // @ts-expect-error
      onChange={onChange}
      options={options}
      valueComponent={valueComponent}
      optionComponent={optionComponent}
    />
  );
};

const WrappedPrivacySelect = withTranslation()(PrivacySelectComponent);

type FieldProps = {
  input: {
    value: string,
    onChange: (newValue: string) => void,
  },
  onlyBoolean?: boolean,
};

const ForField = ({ input, onlyBoolean }: FieldProps) => (
  <WrappedPrivacySelect value={input.value} onChange={input.onChange} onlyBoolean={onlyBoolean} />
);

export const PrivacyFieldSelect = (props: Omit<BaseFieldProps<Omit<FieldProps, 'input'>>, 'component'>) => (
  <Field {...props} component={ForField} />
);

export default WrappedPrivacySelect;
